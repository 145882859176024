.about-section-text {
  text-align: justify;
}

#about {
  background-color: #0f599e;
  color: #ffffff;
}

@media screen and (max-width: 600px) {
  #about .section-title {
    margin-bottom: 2rem;
  }
}
