.bg-theme-color {
  background-color: #579fe2;
  color: #ffffff;
}

#services .timeline-item .icon {
  margin: 0 auto;
  width: 48px;
  height: 48px;
  background: #1e75c7;
  border-radius: 4px;
  display: inline-blocks;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  transform-style: preserve-3d;
  font-size: 4rem;
}

#services .timeline-item:hover .icon::before {
  background:#ffffff;
}

#services .timeline-item .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}

@media only screen and (max-width:767px) {
  #services .animate__animated.animate__fadeInUp {
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }

  #services .timeline-item .icon {
    margin-left:15px;
    margin-right:0;
  }

}