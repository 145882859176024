.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
  width: 100%;
  max-width: 800px;
}
.mb-5.mb-lg-0.col-md-12 {
  width: 100%;
  max-width: 800px;
}
.col-md-4.col-sm-4 {
  margin: 0 auto;
}
p.para {
  text-align: left;
}
button.theme-button {
  padding: 0.5rem 6rem;
  background-color: #0151b1;
  border-radius: 50px;
  border-width: 0.1rem;
  border-style: solid;
  border-color: #0151b1;
  font-size: 1.2rem;
}

button.theme-button:hover {
  background-color: #0f599e;
  border-color: #ffffff;
}
