#objectives {
  position: relative;
  overflow: hidden;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #579fe2;
}

#objectives::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40%;
  z-index: -1;
}

.objective-inner {
  background-color: #fff;
  border: 2px solid #0f599e;
  padding: 30px 30px 35px 30px;
  margin-bottom: 30px;
  transition: 0.4s;
  border-radius: 1rem;
  min-height: 320px;
  font-family: "Roboto", sans-serif;
}

.objective-inner h5 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #0f599e;
  min-height: 50px;
}

.objective-inner p {
  margin-bottom: 0;
  font-weight: 600;
  color: #344552;
}

.objective-inner:hover {
  box-shadow: 0px 6px 30px 0px rgba(40, 126, 184, 0.2);
}
