.bg-theme-color {
  background-color: #579fe2;
  color: #ffffff;
}

.whychooseus-card {
  padding: 1.5rem;
  width: 100%;
  background-color: white;
  border: 2px solid #0f599e;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: start;
  margin-bottom: 3rem;
  min-height: 250px;
}

.whychooseus-card::before {
  content: attr(data-num);
  position: absolute;
  top: -2rem;
  left: 1rem;
  font-weight: bold;
  font-size: 2rem;
  color: #ffffff;
  background-color: #0f599e;
  width: 64px;
  height: 64px;
  padding: 1rem;
  line-height: 2rem;
  text-align: center;
  border-radius: 4px;
}

.whychooseus-card-body {
  padding: 1rem 0;
}

.whychooseus-card-body .objective-card-text {
  text-align: justify;
}

.whychooseus-card:hover .img-container {
  padding: 0.2rem;
}

.whychooseus-card-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: "Roboto", sans-serif;
  color: #0f599e;
}
