.bg-theme-color {
  background-color: #579fe2;
  color: #ffffff;
}

.single-schedules-inner {
  background-color: #fff;
  border: 2px solid #579fe2;
  padding: 30px 30px 35px 30px;
  margin-bottom: 30px;
  transition: 0.4s;
  border-radius: 1rem;
  min-height: 200px;
  font-family: "Roboto", sans-serif;
}

.single-schedules-inner h5 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #0f599e;
}

.single-schedules-inner p {
  margin-bottom: 0;
  font-weight: 600;
  color: #344552;
}

.single-schedules-inner:hover {
  box-shadow: 0px 6px 30px 0px rgba(40, 126, 184, 0.2);
}

/*
  contact us
  */
@media only screen and (max-width: 767px) {
  .animate__animated.animate__fadeInUp {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
